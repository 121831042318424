import request from '@/utils/request';

// 获取我的直播
export const liveList = params => request.post(`study/live/list`, params)

// 获取直播详情
export const liveDetail = params => request.get(`study/live/${params.taskId}/${params.detailId}/detail`)

// 观看直播
export const liveLearn = params => request.get(`study/live/${params.taskId}/${params.detailId}/log`)

// 企业微信获取直播状态
export const getWxLiveStatus = params => request.get(`study/live/${params.taskId}/${params.detailId}/wxdetail`)

// 钉钉获取直播状态
export const getDdLiveStatus = params => request.get(`study/live/${params.taskId}/${params.detailId}/dddetail`)