<template>
  <div class="room">
    <a-spin :spinning="spinning" :delay="500">
      <iframe
        :src="url"
        width="100vw"
        height="100vh"
        frameborder="0"
        :allowfullscreen="true"
        allow="microphone; camera"
      />
    </a-spin>
  </div>
</template>

<script>
import { ref, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { liveDetail, liveLearn } from "@/api/live";
export default {
  setup() {
    const route = useRoute();
    const taskId = parseInt(route.query.id || 0);
    const detailId = parseInt(route.query.did || 0);
    const spinning = ref(true);

    const url = ref("");
    let timer = null;

    liveDetail({
      taskId,
      detailId,
    }).then((res) => {
      if (res.ret == 0) {
        let d = res.data;
        document.title = d.roomName;
        url.value = d.watchUrl;
        spinning.value = false;
      } else {
        setTimeout(() => {
          window.close();
        }, 1000);
      }
    });

    liveLearn({
      taskId,
      detailId,
    });

    timer = setInterval(() => {
      liveLearn({
        taskId,
        detailId,
      });
    }, 1000 * 30);

    onUnmounted(() => {
      if (timer) {
        clearInterval(timer);
      }
    });

    return {
      spinning,
      url,
    };
  },
};
</script>

<style lang="less" scoped>
.room {
  iframe {
    margin: 0 auto;
    display: block;
    width: 100vw;
    height: 100vh;
  }
}
</style>